import $ from 'jquery';
import { initSelect2 } from './select2';

export function initBusinessUnitFiltering() {
  var initialValue = $
  ('#initial-business-unit').val();
  var businessUnits = $
  ('#booking_form_business_unit_id').html();

  handleBusinessUnitFiltering(businessUnits, initialValue);

  $('#booking_form_organisation_id').change(function() {
    handleBusinessUnitFiltering(businessUnits);
  })
}


function handleBusinessUnitFiltering(businessUnits, initialValue) {

  var organisation = $('#booking_form_organisation_id :selected').val();
  var options = '<option value="">Please select</option>'

  options += $(businessUnits).filter('optgroup[label="' + organisation + '"]').html();

  if (options) {
    $('#booking_form_business_unit_id').html(options);
  } else {
    $('#booking_form_business_unit_id').html($("<option>"));
  }

  initSelect2();

  if (initialValue) {
    $('#booking_form_business_unit_id').val(initialValue);
  }

  $('#booking_form_business_unit_id').trigger('change');
}
