import $ from 'jquery';

export function initTimeAndDate() {
  // http://www.jonthornton.com/jquery-timepicker/ - documentation for timepicker
  $('.time-picker').timepicker({
    timeFormat: 'g:i A',
    appendTo: function($el) { return $el.parent()},
    scrollDefault: '07:30',
    minTime: '6am',
    maxTime: '8:30pm'
  });

  $('.date-picker').datepicker({
    dateFormat: "dd/mm/yy",
    changeMonth: true,
    changeYear: true
  });
}
