$(document).on("turbolinks:load", function () {
  $(".comment__edit-btn").click(function () {
      var card = $(this).closest('.card')
      $(this).parent().toggle();
      card.find('.comment__text-label').toggle();
      card.find('.comment__form-edit').toggle();
    });

  $(".comment__cancel-btn").click(function () {
      var card = $(this).closest('.card')
      card.find('.comment__form-edit').toggle();
      card.find('.comment__text-label').toggle();
      card.find('.comment__action-btn').toggle();
    });

    // For displaying comment tooltip on booking listing page
    $(document).ready(function(){
      $('[data-toggle="tooltip"]').tooltip();
    });
});