// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Import packages after yarn installing
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'bootstrap-colorpicker';
import 'select2';
import 'bs-custom-file-input';
import 'jquery-ui';
import 'jquery-ui-touch-punch';
import 'timepicker';
import 'moment';
import 'daterangepicker';
import 'html2canvas';
import ActiveStorageDragAndDrop from 'active_storage_drag_and_drop'

ActiveStorageDragAndDrop.start()

global.$ = jQuery;

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("chartkick")
require("chart.js")

// All custom JS under here
require("custom/helpers/select2");
require("custom/homepage");
require("custom/dashboards");
require("custom/admin/organisations/form");
require("custom/admin/questions/form");
require("custom/admin/assessment_forms/form");
require("custom/bookings/form");
require("custom/bookings/comments");
require("custom/bookings/toggle");
require("custom/calendar");
require("custom/appointments");
require("custom/admin/invoices");
require("custom/assessments/true-false");
require("custom/admin/clinics");
require("custom/assessments/update-assessment");
require("custom/common")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
