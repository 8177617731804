import $ from 'jquery';

export function mergeFieldWithErrorsForCustomCheckbox() {
  $('.field_with_errors .signature-checkbox, .field_with_errors .form-checkbox, .field_with_errors .custom-file-input, .field_with_errors .form-radio-button').each(function() {
    var errorDiv = $(this).parent();
    var wrapperField = errorDiv.parent();
    wrapperField.children('.form-check-label, .custom-file-label, .summary__risk-label').insertAfter($(this));  
  });

}
