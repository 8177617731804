import $ from "jquery";
import { initSelect2 } from "../../helpers/select2";
import { initTimeAndDate } from '../../helpers/timeAndDate';
import bsCustomFileInput from 'bs-custom-file-input';

$(document).on("click", ".remove-button", function(event) {
  var oldRecord = $(this).attr("data-old-record");
  var removeMessage = $(this).attr("data-remove-message");

  if (oldRecord == "true") {
    if (!confirm(removeMessage)) {
      return;
    };
  }

  $(this)
    .prev("input[type=hidden]")
    .val(1);
  var fieldset = $(this).closest("fieldset");
  var parent = fieldset.parent();
  fieldset.hide().remove();
  parent.prepend(fieldset);
  reOrderElements(parent);
  event.preventDefault();
});

$(document).on("click", ".add-button", function(event) {
  var time = new Date().getTime();
  var regexp = new RegExp($(this).data("id"), "g");
  $(this)
    .parent()
    .children(".repeater")
    .append(
      $(this)
        .data("fields")
        .replace(regexp, time)
    );
  initSelect2();
  initTimeAndDate();
  reOrderElements(
    $(this)
      .parent()
      .children(".repeater")
  );
  initAssessmentForms();
  bsCustomFileInput.init();
  event.preventDefault();
});

function reOrderElements(target) {
  $(target).each(function() {
    $(this)
      .children("fieldset:visible")
      .each(function(index) {
        var order = index + 1;
        $(this).css({ order: order });
        $(this)
          .children(".label")
          .text("Question #" + order);
        $(this)
          .children(".order") // eslint-disable-line
          .val(order);
      });
  });
}

// Function handles hiding the
function handleHideOther() {
  $(".dynamic-form-section__options").each(function() {
    var element = $(this);
    showActiveSection(element);
  });
}

$(document).on(
  "click",
  ".remove-sub-section, .remove-section-question",
  function(event) {
    var parent = $(this).parents(".dynamic-form-section");
    if (parent.length > 0) {
      showActiveSection(
        parent.first().find(".dynamic-form-section__options")
      );
    }
  }
);

function showActiveSection(section) {
  if (
    section
      .find(".dynamic-form-section-questions")
      .children(".dynamic-form-section-question:visible").length > 0
  ) {
    section
      .find(".sub-sections__wrapper")
      .hide()
      .removeClass("active");
    section.find(".questions__wrapper").addClass("active");
    section.removeClass("d-flex");
  } else if (
    section
      .find(".dynamic-form-sub-sections")
      .children(".dynamic-form-sub-section:visible").length > 0
  ) {
    section
      .find(".questions__wrapper")
      .hide()
      .removeClass("active");
    section.find(".sub-sections__wrapper").addClass("active");
    section.removeClass("d-flex");
  } else {
    section
      .find(".sub-sections__wrapper")
      .show()
      .removeClass("active");
    section
      .find(".questions__wrapper")
      .show()
      .removeClass("active");
    section.addClass("d-flex");
  }
}

function setOrderOnLoad() {
  $('.order').each(function() {
    var order = $(this).val();
    $(this).parent().css({ order: order });
  })
}

function initAssessmentForms() {
  $(".dynamic-form-sections").sortable({
    handle: ".drag-icon",
    cursor: "grabbing",
    stop: () => reOrderElements(".dynamic-form-sections")
  });

  $(".dynamic-form-section-questions").sortable({
    handle: ".drag-icon",
    cursor: "grabbing",
    stop: () => reOrderElements(".dynamic-form-section-questions")
  });

  $(".dynamic-form-sub-sections").sortable({
    handle: ".drag-icon",
    cursor: "grabbing",
    stop: () => reOrderElements(".dynamic-form-sub-sections")
  });

  $(".dynamic-form-sub-section-questions").sortable({
    handle: ".drag-icon",
    cursor: "grabbing",
    stop: () => reOrderElements(".dynamic-form-sub-section-questions")
  });

  $(".dynamic-form-section__close-button")
    .off()
    .on("click", function(event) {
      event.preventDefault();
      $(this).toggleClass("active");
      $(this)
        .parents(".dynamic-form-section")
        .children(".dynamic-form-section__options")
        .toggleClass("hidden");
    });

  setOrderOnLoad();
  handleHideOther();
}

$(document).on("turbolinks:load", function() {
  initAssessmentForms();
});
