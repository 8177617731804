$(document).on("turbolinks:load", function () {
  $('#csv_date_range').on('apply.daterangepicker', function(event, picker) {
    var startDate = picker.startDate.format('DD/MM/YYYY');
    var endDate = picker.endDate.format('DD/MM/YYYY');
    var linkElement = $('#download_csv');
    var link = linkElement.attr('href');
    var linkStart = link.split('=')[0];
    var linkEnd = linkStart + "=" + encodeURI(startDate + '-' + endDate);
  
    linkElement.attr('href', linkEnd);
  });

  $('#download_csv').on('click', function() {
    $('#export_by_date_modal').modal('hide');
  })
});
