$(document).on("turbolinks:load", function () {
  $(".true-false.has-comment:not(.required-question) .form-radio-button:checked").each(function () {
    setTrueFalseValidation($(this));
  });

  $(".true-false.has-comment:not(.required-question) .form-radio-button").change(function () {
    setTrueFalseValidation($(this));
  });
});

function setTrueFalseValidation(element) {
  if (element.val() === "True") {
    element
      .parents(".true-false")
      .find(".true-false__text-area")
      .prop("required", true);
  } else {
    element
      .parents(".true-false")
      .find(".true-false__text-area")
      .prop("required", false);
  }
}
