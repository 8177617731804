import $ from 'jquery';
import { initSelect2 } from './helpers/select2';
import { initBusinessUnitFiltering } from './helpers/businessUnitFiltering';
import { initTimeAndDate } from './helpers/timeAndDate';
import { mergeFieldWithErrorsForCustomCheckbox } from './helpers/formHelpers';
import bsCustomFileInput from 'bs-custom-file-input';

$(document).on("click", ".header__login-button", function() {
  $("#session-header-modal").toggleClass("active");
  $(this).toggleClass("active");
});

$(document).on("click", function(event) {
  const modalElement = $("#session-header-modal").get(0);
  const buttonElement = $(".header__login-button").get(0);
  let targetElement = event.target; // clicked element
  do {
    if (targetElement == modalElement || targetElement == buttonElement) {
      // This is a click inside. Do nothing, just return.
      return;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  // This is a click outside
  $("#session-header-modal").removeClass("active");
  $(".header__login-button").removeClass("active");
});

$(document).on("turbolinks:load", function() {
  initBusinessUnitFiltering();
  initSelect2();
  initTimeAndDate();
  mergeFieldWithErrorsForCustomCheckbox();

  bsCustomFileInput.init();

  $('.logged-header__active').click(function() {
    $(this).parent().toggleClass('active');
  });

  $('.pop-over').popover({
    trigger: 'focus'
  });
});


