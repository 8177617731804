$(document).on("turbolinks:load", function () {
  $(".clinic-contacts").sortable({
    handle: ".drag-icon",
    cursor: "grabbing",
    stop: () => reOrderElements(".clinic-contacts"),
  });
});

function reOrderElements(target) {
  $(target).each(function () {
    $(this)
      .children("fieldset:visible")
      .each(function (index) {
        var order = index + 1;
        $(this).css({ order: order });
        $(this)
          .children(".order") // eslint-disable-line
          .val(order);
      });
  });
}
