import $ from 'jquery';
import 'select2';
import 'bootstrap-colorpicker';
import { destroySelect2, initSelect2 } from '../../helpers/select2';

$(document).on("click", '.add-business-unit', function (event) {
  var time = new Date().getTime();
  var regexp = new RegExp($(this).data('id'), 'g');
  destroySelect2();
  $(this).before($(this).data('fields').replace(regexp, time))
  initSelect2();
  event.preventDefault()
});

$(document).on("click", '.remove-business-unit', function (event) {
  $(this).prev('input[type=hidden]').val(1);
  $(this).closest('fieldset').hide();
  event.preventDefault();
});

$(document).on('change', '.industry-select__industry', function() {
  handleFilteringIndustries(this);
});


$(document).on('turbolinks:load', function() {
  $('.colour-picker').colorpicker();

  var industryOptions = $('.industry-select__sub-industry').html();

  $('.industry-select__industry').each(function() {
    handleFilteringIndustries(industryOptions, this);
  })

  $(document).on('change', '.industry-select__industry', function() {
    handleFilteringIndustries(industryOptions, this);
  })

})


function handleFilteringIndustries(industryOptions, industryElement) {
  var value = $(industryElement).val();
  var options = $(industryOptions).filter('optgroup[label="'  + value +'"]').html();
  var $subIndustryElement = $(industryElement).parents('.industry-select').find('.industry-select__sub-industry');

  if (options) {
    $subIndustryElement.html(options);
  } else {
    $subIndustryElement.html($("<option>").attr('selected',true));
  }
}
