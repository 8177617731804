import html2canvas from "html2canvas";

$(document).on("turbolinks:load", function () {
    $(".daterange").daterangepicker({
      locale: {
        format: "DD/MMM/YYYY",
      },
    });
    
  $(".full-size-toggle").click(function () {
    $(this).closest(".chart-wrapper").toggleClass("full-size");
    $(this).toggleClass("fa-expand-alt");
    $(this).toggleClass("fa-times");
  });

  $(".chart__print").click(function () {
    var chart = $(this).parents(".chart-wrapper")[0];
    var fileName = $(this).attr("data-title");
    html2canvas(chart, {
      windowWidth: 1400,
    }).then((canvas) => {
      var a = document.createElement("a");
      a.href = canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      a.download = fileName + ".jpg";
      a.click();
    });
  });

  $("#compare-business-units").click(function () {
    var value = !$(".compare-business-units-check-box").is(":checked");
    var company = $(".company-selected_company").find(":selected").val();

    $.ajax({
      type: "POST",
      url: "/dashboard/business_unit_comparison_options",
      data: { value: value, company: company },
      success: function () {
        return;
      },
    });
  });
  

  //This ensures if a company value is selected, that the BU and candidate select dropdowns dynamically update on page refresh
  $(document).ready(function(){
    var company = $(".company-selected_company").find(":selected").val();
    if (company) {
      $.ajax({
        type: "POST",
        url: "/dashboard/dashboard_filter_company",
        data: { value: company },
        success: function () {
          return;
        },
      });
    }
  });

  // To change company selected.
  $(document).on('change', '.company-selected_company', function() {
    var value = this.value;
    processCompanyFilter (value);
  });

  function processCompanyFilter (value){
    var comparedCheckbox = $(".compare-business-units-check-box").val();
    $.ajax({
      type: "POST",
      url: "/dashboard/dashboard_filter_company",
      data: { value: value, compared: comparedCheckbox },
      success: function () {
        return;
      },
    });
  }
});
