$(document).on("change", ".assign-assessment", function (event) {
  var medical_professional_id = event.target.value;
  var assessment_id = $(this).data("assessment-id");

  $.ajax({
    type: "POST",
    url: "/assessments/" + assessment_id + "/set_medical_professional",
    data: { assessment: { user_id: medical_professional_id } },

    success: function () {
      return;
    },
  });
});

$(document).on("change", ".assign-role", function (event) {
  var role = event.target.value;
  var assessment_id = $(this).data("assessment-id");

  $.ajax({
    type: "POST",
    url: "/assessments/" + assessment_id + "/set_medical_professional",
    data: { assessment: { assigned_role: role } },

    success: function () {
      return;
    },
  });
});
