import $ from 'jquery';


$(document).on("change", '.bookings-toggle input', function () {
  let dateHeading = $('.bookings__table-date-heading');
  let timeHeading = $('.bookings__table-time-heading');
  let isChecked = $(this).is(":checked");
  // If Checked is true - set booking
  if (isChecked) {
    dateHeading.text("Booking Date");
    timeHeading.text("Booking Time");
  } else{
    dateHeading.text("Appointment Date");
    timeHeading.text("Appointment Time");
  }
  toggleDateTimes(isChecked);
});

function toggleDateTimes (isChecked){
  let bookingsTableDate = $(`.bookings__table-date`);
  let bookingsTableTime = $(`.bookings__table-time`);
  // Toggle booking/appt date
  if (isChecked) {
    bookingsTableDate.each(function(  ) {
      let bookingDate = $(this).children('.bookings__table-booking-date');
      let apptDate = $(this).children('.bookings__table-appt-date');
      apptDate.addClass( "d-none" );
      bookingDate.removeClass( "d-none" );
    });
  } else{
    bookingsTableDate.each(function(  ) {
      let bookingDate = $(this).children('.bookings__table-booking-date');
      let apptDate = $(this).children('.bookings__table-appt-date');
      apptDate.removeClass( "d-none" );
      bookingDate.addClass( "d-none" );  
    });
  }

  // Toggle booking/appt time
  if (isChecked) {
    bookingsTableTime.each(function(  ) {
      let bookingTime = $(this).children('.bookings__table-booking-time');
      let apptTime = $(this).children('.bookings__table-appt-time');
      apptTime.addClass( "d-none" );
      bookingTime.removeClass( "d-none" );
    });
  } else{
    bookingsTableTime.each(function(  ) {
      let bookingTime = $(this).children('.bookings__table-booking-time');
      let apptTime = $(this).children('.bookings__table-appt-time');
      apptTime.removeClass( "d-none" );
      bookingTime.addClass( "d-none" );   
    });
  }
}


 

