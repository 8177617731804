import $ from 'jquery';

$(document).on('click', '.question-field__show-comment', function(event) {
  event.preventDefault();
  
  $(this).parent().siblings('.question-field__comment').toggleClass('d-none');
});


$(document).on("change", ".assign-admin", function (event) {
  console.log('assign admin')
  var admin_id = event.target.value;
  var booking_id = $(this).data("booking-id");

  $.ajax({
    type: "POST",
    url: "/bookings/" + booking_id + "/set_assigned_admin",
    data: { booking: { assigned_admin_id: admin_id } },

    success: function () {
      return;
    },
  });
});
