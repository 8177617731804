import $ from 'jquery';
import 'select2';

export function initSelect2() {
  if ($('.select2').length) {
    $('.select2').remove();
  }

  $(".custom-select").select2({
    placeholder: "Please select",
    allowClear: true,
    templateResult: formatState,
    templateSelection: formatState
  });

  $(".company-select").select2({
    placeholder: "Default: All Companies",
    allowClear: true,
    templateResult: formatState,
    templateSelection: formatState
  });

  $('.custom-select').on('select2:open', function(e) {
    $(".select2-search__field").prop('placeholder', 'Search')
  });
}

export function destroySelect2() {
  $(".custom-select").select2("destroy");
}


function formatState(state) {
  if (!state.id) {
    return state.text;
  }
  var $state = $(
    '<span>' + state.text + '</span>'
  );
  return $state;
}
