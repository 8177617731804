import $ from 'jquery';

$(document).on("change", "#question-type", function(event) {
  var questionType = event.target.value;
  var questionOptionTypes = ['dropdown', 'radio', 'checkbox', 'radio_with_required_comment'];
  var uploadTypes = ['upload_download']

  var $questionOptions = $('.question-options');
  var $uploadOptions = $('.upload-options');

  if (questionOptionTypes.includes(questionType)) {
    $questionOptions.removeClass('hidden');
  } else {
    $questionOptions.addClass('hidden');
  }

  if (uploadTypes.includes(questionType)) {
    $uploadOptions.removeClass('d-none');
  } else {
    $uploadOptions.addClass('d-none');
  }
  
})

$(document).on("click", '.add-question-option', function (event) {
  var time = new Date().getTime();
  var regexp = new RegExp($(this).data('id'), 'g');
  $(this).before($(this).data('fields').replace(regexp, time))
  event.preventDefault()
});

$(document).on("click", '.remove-question-option', function (event) {
  $(this).prev('input[type=hidden]').val(1);
  $(this).closest('fieldset').hide();
  event.preventDefault();
});



$(document).on("click", '.form-radio-button', function () {
  var attr = this.getAttribute('data-radio-id');
  openFirstComment (this.className, attr);
});

function openFirstComment (radioClasses, attr){
  if (radioClasses.includes('form-radio-button_with-comment')){
    $(`.question-field__first-comment_${attr}`).removeClass( "d-none" );
    // Comment box required
    $(`.question-field__first-comment_${attr} .form-control`).attr('required', true); 
    $(`.show-comment-add_${attr}`).addClass( "d-none" );
  } else{
    $(`.question-field__first-comment_${attr}`).addClass( "d-none" );
    // Remove required from comment box to enable proceeding
    $(`.question-field__first-comment_${attr} .form-control`).attr('required', false); 
    $(`.show-comment-add_${attr}`).removeClass( "d-none" );
  }
}

