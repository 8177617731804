$(document).on("turbolinks:load", function () {
  $(".question-history__close-button").click(function () {
    event.preventDefault();
    $(this).toggleClass("active");
    $(this)
      .parents(".question-history")
      .children(".question-history__history")
      .toggleClass("d-none");
  });

  // Update required status on all required checkboxes on page load
  $(".question-field__required-checkboxes").each(function () {
    toggleRequiredCheckboxes($(this));
  });

  // Update required status on all required checkboxes when values change
  $(".form-checkbox--required").change(function () {
    var requiredCheckboxesWrapper = $(this).parents(
      ".question-field__required-checkboxes"
    );

    toggleRequiredCheckboxes(requiredCheckboxesWrapper);
  });
});

/**
 * Handles toggling html5 validation on checkbox multi-select groups
 *
 * @param {jQuery element} requiredCheckboxesWrapper - Any element with the
 * `.question-field__required-checkboxes` class applied
 */
function toggleRequiredCheckboxes(requiredCheckboxesWrapper) {
  if (
    requiredCheckboxesWrapper.find('input[type="checkbox"]:checked').length <= 0
  ) {
    requiredCheckboxesWrapper.find('input[type="checkbox"]').each(function () {
      $(this).prop("required", true);
    });
  } else {
    requiredCheckboxesWrapper.find('input[type="checkbox"]').each(function () {
      $(this).prop("required", false);
    });
  }
}
