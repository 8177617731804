import $ from 'jquery';

$(document).on("turbolinks:load", function() {
  $('.month__overflow-button').on('click', function() {
    $(this).siblings('.month__popup').addClass('active');
  });

  if ($('.day__time-indicator').length) {
    showTime('.day__time-indicator', '.time-slot__outer-label');
  }

  if ($('.week__time-indicator').length) {
    showTime('.week__time-indicator', '.week__time-cell');
  }
});


function showTime(timeIndicatorClass, appendToClass){
  var date = new Date();
  var h = date.getHours();
  var m = date.getMinutes();
  var session = "AM";
  var minutesInHour = 60;

  if (h == 12) {
    session = 'PM'
  }
  
  if(h == 0){
      h = 12;
  }
  
  if(h > 12){
      h = h - 12;
      session = "PM";
  }
  
  m = (m < 10) ? "0" + m : m;
  
  var time = h + ":" + m + " " + session;
  var currentHour = $('.time-division[data-time="' + date.getHours() + '"]');
  var topPosition = (m / minutesInHour) * 100;
  var timeIndicator = $(timeIndicatorClass);

  if (currentHour.length) {
    timeIndicator.appendTo(currentHour.find(appendToClass));

    timeIndicator.css({
      top: topPosition + '%'
    })

    timeIndicator.html(time);
    timeIndicator.show();
  } else {
    timeIndicator.hide();
  }

  setTimeout(showTime, 5000);
}



$(document).on('click', function(event) {
  let targetElement = event.target; // clicked element
  do {
    if (targetElement && targetElement.classList && (targetElement.classList.contains("month__popup") ||  targetElement.classList.contains("month__overflow-button"))) {
      return;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  // This is a click outside
  $('.month__popup').removeClass('active');
});


